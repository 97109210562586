import React, { useState, useEffect } from "react";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { SelectButton } from "primereact/selectbutton";
//import "primereact/resources/themes/lara-light-indigo/theme.css";
import 'primereact/resources/themes/saga-blue/theme.css'; // Importing PrimeReact theme
import 'primereact/resources/primereact.min.css'; // Importing PrimeReact core CSS
import 'primeicons/primeicons.css'; // Importing PrimeIcons
import AxiosService from "../../../Utils/AxiosService";
import { toast } from "react-toastify";
import DateFormat from "./DateFormat";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus ,faToggleOn,faToggleOff } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
const CommentsWithTree = ({ label, data, sectionRef }) => {
  const [nodes, setNodes] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [filterMode, setFilterMode] = useState("lenient");
  const apiService = new AxiosService();
  const [expandedKeys, setExpandedKeys] = useState({});
  const [isToggled, setIsToggled] = useState(true);

  useEffect(() => {
    apiService
      .getAllTreeComments(label)
      .then((response) => {
        if (response.data) {
          //const groupedData = groupByAndSort(response.data, "sectionNo");
          const groupedData = groupByAndSortAsArray(response.data);
          const treeTableData = convertToTreeTable(groupedData);
          setNodes(treeTableData);
          setIsToggled(true);
          expandAll();
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Something went wrong!");
        }
      });
  }, [data]);

  const convertToTreeTable = (groupedData) => {
    return groupedData.map((group) => {
      const sectionNo = Object.keys(group)[0];
      const items = group[sectionNo];
      return {
        key: sectionNo,
        sectionNo: sectionNo,
        data: { sectionNo: sectionNo },
        children: items.map((item, index) => ({
          key: sectionNo + "-" + index,
          sectionNo: item.sectionNo,
          data: item,
        })),
      };
    });
  };

  const groupByAndSortAsArray = (obj) => {
    const keys = Object.keys(obj);

    keys.sort((a, b) => {
      // Convert keys to numbers if possible for numeric comparison
      const numA = parseFloat(a);
      const numB = parseFloat(b);

      // If both keys are numeric, compare them as numbers
      if (!isNaN(numA) && !isNaN(numB)) {
        return numA - numB;
      }

      // Otherwise, compare them as strings
      return a.localeCompare(b);
    });

    const sortedArray = keys.map((key) => ({ [key]: obj[key] }));
    return sortedArray;
  };

  const getHeader = () => {
    return (
      <div className="flex justify-content-end">
        <IconField iconPosition="left">
          <InputIcon className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Global Search"
          />
        </IconField>
      </div>
    );
  };

  const renderDate = (rowData) => {
    const timeStamp = rowData?.data?.timeStamp;
    if (timeStamp !== undefined) {
      return <DateFormat data={timeStamp} />;
    } else {
      return "";
    }
  };
  let header = getHeader();

  const expandAll = () => {
      let _expandedKeys = {};
      for (let node of nodes) {
        expandNode(node, _expandedKeys);
      }
      setExpandedKeys(_expandedKeys);
      setIsToggled(false);
  };

  const collapseAll = () => {
    setExpandedKeys({});
    setIsToggled(true);
  };

  const expandNode = (node, _expandedKeys) => {
    if (node.children && node.children.length) {
      _expandedKeys[node.key] = true;

      for (let child of node.children) {
        expandNode(child, _expandedKeys);
      }
    }
  };

  // const dateFilter = (value, filter) => {
  //   if (filter === undefined || filter === null || filter.trim() === '') {
  //     return true;
  //   }

  //   const filterDate = moment(filter, 'DD/MM/YYYY, hh:mm:ss a');
  //   const rowDate = moment(value);

  //   return rowDate.isSameOrAfter(filterDate);
  // };

  const dateFilter = (value, filter) => {
    if (filter === undefined || filter === null || filter.trim() === '') {
      return true; // If no filter or empty filter, show all rows
    }
  
    const formattedValue = moment(value).format('DD/MM/YYYY, hh:mm:ss a').toLowerCase();
    const formattedFilter = filter.toLowerCase();
  
    console.log(formattedFilter , formattedValue)
    return formattedValue.includes(formattedFilter);
  };

  

  const onCustomFilterChange = (event) => {
    console.log(event)
    setGlobalFilter(event.target.value);
  };

  return (
    <div className="card">
      <h5 class="card-header ">All Comments by Section Number</h5>
      <div className="flex justify-content-center mb-3" ref={sectionRef}>
        <SelectButton
          value={filterMode}
          onChange={(e) => setFilterMode(e.value)}
        />
      </div>
      <div className="button-container p-2">
      <Button onClick={isToggled ? expandAll : collapseAll} className="btn btn-light" title="Toggle">
      <FontAwesomeIcon icon={isToggled ? faToggleOn : faToggleOff} />
    </Button>
      </div>
      <TreeTable
        value={nodes}
        globalFilter={globalFilter}
        filterMode={filterMode}
        tableStyle={{ minWidth: "50rem" }}
        expandedKeys={expandedKeys}
        onToggle={(e) => setExpandedKeys(e.value)}
        className="border"
      >
        <Column
          field="sectionNo"
          header="Section No"
          expander
          filter
          filterPlaceholder="Filter by Section No"
          className="border-bottom"
        ></Column>
        <Column
          field="comment"
          header="Comment"
          filter
          filterPlaceholder="Filter by Comment"
          className="border-bottom"
        ></Column>
        <Column
          field="username"
          header="Added by"
          filter
          filterPlaceholder="Filter by Added by"
          className="border-bottom"
        ></Column>
        <Column
          field="timeStamp"
          header="Added on"
          filter
          filterPlaceholder="Filter by Added on"
          className="border-bottom"
          //   filterElement={
          //     <InputText type="text" onChange={onCustomFilterChange} placeholder="Filter by Added on" />
          //   } 
          // filterFunction={dateFilter}
        />
      </TreeTable>
    </div>
  );
};

export default CommentsWithTree;
